/* TOTALURI MOBIL COMPONENT */
#totaluri-judet-container {
    margin: 8px;
    border-radius: 8px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background: #e6d2d1;
}

#totaluri-wrapper-desktop {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

#totaluri-judet-container-desktop {
    margin: 8px;
    border-radius: 8px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background: #e6d2d1;

    grid-column: 2;
    margin-top: 10vh;
    margin-bottom: 2em;
}

#totaluri-judet-container-desktop * {
    margin-bottom: 10px;
}

#totaluri-judet-container {
    margin: 8px;
    border-radius: 8px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background: #e6d2d1;
}

#totaluri-judet-container #desc {
    margin-bottom: 8px;
}

#totaluri-judet-container #licitatii {
    margin-top: 8px;
    margin-bottom: 8px;
}

#header-case {
    display: grid;
    grid-template-columns: 0.5fr 28% 1fr 1fr 0.5fr;
    color: #f0c5ff;
    border-radius: 15px 15px 0 0;
}

#header-case.desktop {
    grid-template-columns: 0.5fr 1.2fr 10% 1fr 0.8fr 0.5fr 0.5fr;
}

#header-id {
    min-width: 48px;
}

.case-row {
    padding: 10px;
    background: #2b130e;
    margin-bottom: 4px;
    padding-left: 4%;
    padding-right: 4%;
    cursor: pointer;
    display: grid;
    grid-template-columns: 0.5fr 28% 1fr 1fr 0.5fr;
    text-align: center;
}

.case-row.desktop {
    grid-template-columns: 0.5fr 1.2fr 10% 1fr 0.8fr 0.5fr 0.5fr;
    margin-bottom: 0;
}

.case-row * {
    display: grid;
    place-content: center;
}

.selected {
    background-color: #5f1000;
}

.licitatie-id-smaller {
    font-size: 14px;
    background-color: #a4f;
    border: none;
    border-radius: 8px;
    margin-right: 0;
    padding: 4px;
    padding-right: 0;
    padding-left: 0;
    color: white;
    padding-right: 0;
    padding-left: 0;
}

#judete-grid-container-desktop {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}


.licitatie-id-smaller-desktop {
    font-size: 14px;
    background-color: #a4f;
    border: none;
    border-radius: 8px;
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;
}

.all-licitatii-total-price {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    align-self: center;
    padding-right: 13%;
}

.all-licitatii-total-price-header {
    padding-right: 10%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.interesanta-smaller {
    background-color: #a3146c;
    padding: 2px;
    border-radius: 50%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0px;
    font-size: 16px;
}

#salvata-header .interesanta-smaller {
    background-color: #a4f;
}
