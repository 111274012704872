#jud-details-container {
    height: auto;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;
    z-index: 2;
    pointer-events: none;
}

#panel-and-inchide {
    display: flex;
    flex-direction: column;
    z-index: 3;
    pointer-events: all;
}

#jud-details-panel {
    color: #fff;
    width: 200px;
    border-radius: 15px;
    background: #750000;
    display: flex;
    flex-direction: column;
    padding: 1em;
}

#btn-inchide {
    color: #fff;
    height: auto;
    width: 200px;
    border-radius: 15px;
    background: #4b4444;
    padding: 1em;
    margin-top: 10px;
    margin-right: 20px;
    text-align: center;
    cursor: pointer;
}

#title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 1em;
}

#jud-details-panel .row {
    margin-bottom: 10px;
}

#push {
    flex-grow: 1;
}

#raport-button {
    background: #8630a6;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

#toate-button.desktop {
    color: white;
}