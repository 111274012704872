#map-grid {
    display: grid;
    grid-template-columns: 350px auto 280px;
}

#middle {
    display: flex;
    justify-content: center;
}

#nume-jud {
    position: absolute;
    bottom: -40px;
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #E6D2D1;
}

#wrapper-jud-image {
    position: fixed;
    top: 30vh;
    left: 4%;
}

#image-judet-selectat {
    width: 350px;
    aspect-ratio: auto 350 / 350;
    height: 350px;
}
