#licitatii-selectate-container {
    position: fixed;
    top: 0px;
    z-index: 2;
    pointer-events: none;
}

#licitatii-selectate-column {
    width: 100%;
    display: grid;
    place-content: center;
}

#licitatii-selectate {
    color: #fff;
    height: auto;
    width: 25vw;
    border-radius: 15px;
    background: #750000;
    padding-bottom: 15px;
    margin-top: 25vh;
    pointer-events: all;
    min-width: 400px;
}

.licitatie-selectata.desktop {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 15px;
    padding-left: 15px;
}

.pret.desktop {
    margin-right: 14px;
}

#selectii-label {
    margin-bottom: 25px;
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    padding-right: 20px;
}

#selectii-title {
    flex-grow: 1;
}

.selectii-label-vis {
    cursor: pointer;
    height: 1.5em;
    margin-left: 10px;
}

.not-selected {
    opacity: 0.5;
}

#close-jud-btn {
    height: 30px;
    translate: 15px -15px;
    position: absolute;
    right: 0;
    cursor: pointer;
}