
#title-licitatie.desktop {
    background: #2b130e;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
}

.details-btn.desktop {
    margin-right: 0;
}

#details-content-desktop {
    background-color: #2b130e;
    border: none;
    border-radius: 10px;
    margin-right: 3%;
    margin-left: 3%;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 15px;
    padding: 18px;
}

#properties-container-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2em;
}

#properties-desktop {
    display: grid;
    grid-template-columns: 50% auto;
    font-size: 20px;
    margin-bottom: 40px;
    min-width: 20em;
    line-height: 28px;
}

#details-buttons-desktop {
    display: flex;
    flex-direction: column;
}

#details-buttons-desktop * {
    margin-top: 0;
    margin-bottom: 20px;
}