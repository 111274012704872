#total-anunturi {
    position: fixed;
    top: 40px;
    left: 40px;
    border: 2px solid #d71400;
    border-radius: 12px;
    padding: 0.5em;
    font-size: 38px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #E6D2D1;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

#versiunea-desktop {
    font-size: 14px;
    color: #E6D2D1;
    position: fixed;
    top: 19px;
    left: 40px;
}

.label {
    font-size: 0.4em;
}

.totaluri-row {
    display: flex;
    flex-direction: column;
}
