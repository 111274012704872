body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #130D0D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Sa pun aici cele care le pot folosi peste tot */
.button {
  background: #8630a6;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}

.button-direction {
    color: white;
    background-color: #6a1a0b;
    border: none;
    border-radius: 10px;
    margin-right: 8px;
    margin-bottom: 20px;
    padding: 20px;
    padding-left: 15px;
    width: 110px;
    position: absolute;
    top: 20px;
    left: 20px;
}

.m-20 {
  margin: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.no-underline {
  text-decoration: none !important;
}

/* TEXTS */
.grey-text {
  color: #c27d7d;
}

/* FLEX */
.row {
  display: flex;
}

.evenly {
  justify-content: space-evenly;
}