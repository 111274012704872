#message-content {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #0000006e;
    display: grid;
    place-content: center;
}

#message-text {
    color: #16022b;
    font-size: 18px;
}

#inner-content {
    width: 45vw;
    padding: 3em;
    padding-bottom: 2em;
    background-color: #FFF6F0;
    border-radius: 10px;
    text-align: center;
}

#close-button {
    background: #9218f7;
    color: #fff6f0;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 50px;
}