.pad1 {
  padding: 1em;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}

/* JUDETE MOBIL COMPONENT */
#version {
    font-size: 12px;
    text-align: right;
}

#judete-container {
    color: white;
    padding: 8px;
}

.judet-row {
    padding: 10px;
    padding-left: 10px;
    background: #2b130e;
    margin-bottom: 4px;
    padding-left: 13%;
    padding-right: 13%;
    display: flex;
    cursor: pointer;
}

.judet-row .licitatii {
    text-align: right;
    flex-grow: 1;
}

.detalii {
    height: 2em;
}

.judet-row .name-selected {
    color: #f0c5ff;
}

.judet-row .not-enabled {
    color: grey;
}

/* TOTALURIMOBIL COMPONENT */
#totaluri-mobil-container {
    margin: 8px;
    border-radius: 8px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    background: #e6d2d1;
}

#totaluri-mobil-container #data {
    margin-bottom: 8px;
}

#totaluri-mobil-container #licitatii {
    margin-top: 8px;
    margin-bottom: 8px;
}

#totaluri-mobil-container #title {
    margin-bottom: 0.5em;
}

/* DETAILS DROPDOWN COMPONENT */
#dropdown-container {
    color: #f0c5ff;
    background: #88343469;
    padding-top: 13px;
    padding-bottom: 19px;
    box-shadow: 5px 5px 3px 9px #390000;
}

#dropdown-container #licitatii {
    margin-top: 13px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 13px;
}

#dropdown-container #anunturi {
    margin-left: 20px;
    margin-right: 20px;
}

.licitatie-selectata {
    color: #fcf4ff;
    cursor: pointer;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 20px;
    padding-right: 20px;
}

.licitatie-id {
    background-color: #a4f;
    border: none;
    border-radius: 10px;
    margin-right: 8px;
    padding: 4px;
    color: white;
}

.pret {
    margin-right: 8px;
}

.interesanta {
    background-color: #a3146c;
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 50%;
    padding-left: 13px;
    padding-right: 13px;
    margin-right: 5px;
}

.raportata {
    background-color: #616161;
    padding: 5px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 50%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
}

#link {
    text-decoration:  none;
}

#raport-button {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

#toate-button {
    background: #8630a6;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

#link-toate {
    text-decoration: none;
    color: #f0c5ff;
}

/* HEADERSECTION COMPONENT */
#header-container {
    display: grid;
    grid-template-columns: 20% auto;
    color: white;
    padding: 10px;
    padding-bottom: 0;
}

#company-logo {
    width: 100%;
}

#titles-column {
    display: grid;
    place-content: center;
    font-size: 20px;
}
