#buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 4px;
    margin-left: 4px;
    height: 90px;
}

.home-button-wrap {
    flex-grow: 1;
    width: auto;
    padding-right: 4px;
    padding-left: 4px;
}

.home-button {
    background-color: #2B130E;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.wrap2 {
    padding: 20px;
}
