#content {
    padding: 1em;
    color: white;
}

#link {
    text-decoration: none;
    color: white;
}

.link {
    text-decoration: none;
    color: white;
}

#pull-data-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #6a1a0b;
    border: none;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
}

.pull-deactivate {
    transition: background-color 0.5s;
    transition: color 0.5s;
    background: #4a3e3c !important;
    color: #b7b7bd;
    pointer-events: unset;
}

#title-licitatie {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 21px;

    display: grid;
    grid-template-columns: minmax(10%, 1fr) auto minmax(10%, 1fr);
}

#title-content-column {
    grid-column: 2;
}

#title-text{
    text-align: center;
}

.val-subtitle {
    margin-right: 7px;
}

#rand-subtitle {
    display:flex;
    flex-direction: row;
    font-size: 12px;
}

#properties {
    display: grid;
    grid-template-columns: 40% auto;
    font-size: 18px;
    margin: 30px;
}

.value {
    color: #ddb5ff;
    text-align: left;
}

.value.date-value {
    overflow-x: auto;
    white-space: nowrap;
}

.details-btn {
    background-color: #a4f;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 14px;
    text-align: center;
    cursor: pointer;
}

#report-btn {
    background-color: #616161;
}

#save-btn {
    background-color: #a3146c
}

#details-content {
    background-color: #2b130e;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
    padding: 14px;
}
