#search-container {
    padding: 8px;
}

#title-cautare {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
    color: white;
}

#card-search-input {
    border-radius: 8px;
    background: #e6d2d1;
    padding: 1.4em;
    flex-grow: 1;
}

#search-input {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: right;
    font-size: 20px;
}

#search-input:focus-visible {
    outline: none;
}

#search-btn {
    background-color: #a4f;
    color: white;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 14px;
    text-align: center;
    cursor: pointer;
}

