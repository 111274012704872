#properties-container-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2em;
}

#properties-desktop {
    display: grid;
    grid-template-columns: 50% auto;
    font-size: 20px;
    margin-bottom: 40px;
    min-width: 20em;
    line-height: 28px;
}

.harta-btn {
    position: absolute;
    top: 140px;
    left: 20px;
}